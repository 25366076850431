import React from "react";
import { Card, Col, Container, Placeholder, Row, Image } from "react-bootstrap";
import Logo from "../src/logo2.jpg";

const Header = () => {
  return (
    <Container className="bgColorDefault mx-0 px-0 pt-2" fluid>
      <Row className="mx-0 py-2 d-flex justify-content-between position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection header">
        <Row className="col-5 mx-0 pe-3 align-content-center text-start" >
          {
            Logo ?
              <Image src={Logo} className="h-auto w-auto object-fit-fill p-0 mx-auto my-0 selectedThumbnail textShadowDefault boxShadowBlack headerImage"/>
              :
              <Placeholder as={Card.Img} className="h-100 rounded" />
          }
        </Row>
        <Row className="mx-0 position-absolute h-100 py-0 my-0 top-0">
          <Col
            className="mx-auto col-3 col-md-3 col-xl-2 align-self-center bg-dark h-100 py-0 my-0"
            style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)" }}
          ></Col>
        </Row>
        <Row className="col-5 mx-0 align-content-center px-0 text-end py-0 my-0">
          <Col className="text-center textShadowDefault">
            <h1 className="fw-bold fs-1 py-0 my-0">SARGAS</h1>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
export default Header;
import React from "react";
import { Button, Card, Col, Container, Placeholder, Row, Image } from "react-bootstrap";
import Logo from "../src/logo.jpg";

const Footer = () => {
	return (
		<Container className="px-0 " fluid>
			<Row className="mx-0 bgColorDefault position-relative d-flex justify-content-center border-top border-bottom border-5 border-dark boxShadowBlack">
				<Row className="col-auto col-lg-5 col-xl-4 mx-auto mx-lg-0 pt-5 pb-1 pt-lg-0 me-xl-0 pe-xl-0 align-items-center">
					<Col className="text-center mx-1 mx-sm-4 mx-md-5 ms-lg-0 me-xl-0 pe-xl-0 my-3">
						{
							Logo ?
								<Image src={Logo} className="w-100 object-fit-scale rounded selectedThumbnail textShadowDefault boxShadowBlack"/>
								:
								<Placeholder as={Card.Img} height={"150px"} className="w-75 rounded" />
						}
					</Col>
				</Row>
				<Row className="mx-0 col-12 my-5 text-center d-flex align-content-center border-top border-bottom border-5 border-dark d-flex d-lg-none boxShadowBlackSection">
					<Col
						className="col-6 col-sm-5 col-md-4 mx-auto bg-dark h-100"
						style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)", height: "120px", minHeight: "120px" }}
					></Col>
				</Row>
				<Col className="col-auto me-auto ms-auto ms-lg-auto me-lg-4 col-lg-4 pb-5 pt-0 pt-lg-5 text-center">
					<Col className="h1 py-3 pt-lg-0 textShadowDefault fw-bold">KONTAKT INFO.</Col>
					<Col className="fw-bold fs-3">ANTONIA UJVARI</Col>
					<Col className="fw-bold fs-3">Telefon: 097 609 06 22</Col>
					<Col className="fw-bold fs-3">Email: asplus2023@gmail.com</Col>
					<Col className="pt-5 pb-3">
						<Button
							href="tel:00385-97-609-06-22"
							className="border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack bgColorDefault2"
						>NAZOVI ODMAH!
						</Button>
					</Col>
				</Col>
				<Col
					className="col-4 col-xl-4 position-absolute align-self-center bg-dark h-100 d-none d-lg-flex"
					style={{ clipPath: "polygon(30% 0, 100% 0%, 70% 100%, 0% 100%)" }}
				></Col>

			</Row>
			<Row className="mx-0 mb-4">
				<Col className="py-4 text-center text-dark fs-4 fw-bold textShadowDefault boxShadowBlackSection bgColorDefault2">
					Created by: TTN
				</Col>
			</Row>
		</Container>
	);
}
export default Footer;